<aside class="sidenav-container">
    <header>
        <h1>
            <i class="fa fa-arrow-left mr-2 c-pointer text-uex" (click)="close()"></i> {{ title }}
        </h1>
    </header>
    <section>
        <article [innerHtml]="htmlContent"></article>
    </section>
</aside>
