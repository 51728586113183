<div class="uex-dialog-container" tabindex="-1" role="dialog">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title" mat-dialog-title>
					Changement de mot de passe
				</h4>
				<button
					type="button"
					class="close text-white"
					aria-label="Close"
					mat-button
					mat-dialog-close
				>
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<br />
				<div [formGroup]="resetPasswordForm">
					<div class="input-group mb-3 col-md-10 col-sm-12 offset-md-1">
						<div class="input-group-prepend">
							<div
								class="input-group-text bg-white border-right-0"
								[ngClass]="{
									'border-danger':
										errors.newPassword || errors.newPasswordMatch
								}"
							>
								<i class="fa fa-asterisk"></i>
							</div>
						</div>
						<input
							type="password"
							class="form-control border-left-0"
							[ngClass]="{
								'is-invalid': errors.newPassword || errors.newPasswordMatch
							}"
							formControlName="newPassword"
							placeholder="Mot de passe"
						/>
						<div
							class="invalid-feedback"
							*ngIf="errors.newPassword && errors.newPassword?.required"
						>
							New Password field is required.
						</div>
						<div class="invalid-feedback" *ngIf="errors.newPasswordMatch">
							Password did not match.
						</div>
					</div>
					<div class="input-group mb-3 col-md-10 col-sm-12 offset-md-1">
						<div class="input-group-prepend">
							<div
								class="input-group-text bg-white border-right-0"
								[ngClass]="{
									'border-danger':
										errors.confirmNewPassword || errors.confirmNewPasswordMatch
								}"
							>
								<i class="fa fa-asterisk"></i>
							</div>
						</div>
						<input
							type="password"
							class="form-control border-left-0"
							[ngClass]="{
								'is-invalid':
									errors.confirmNewPassword || errors.confirmNewPasswordMatch
							}"
							formControlName="confirmNewPassword"
							placeholder="Confirmation"
						/>
						<div
							class="invalid-feedback"
							*ngIf="errors.confirmNewPassword && errors.confirmNewPassword?.required"
						>
							Confirm Password field is required.
						</div>
						<div class="invalid-feedback" *ngIf="errors.confirmNewPasswordMatch">
							Password did not match.
						</div>
					</div>
				</div>
				<br />
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-outline-info" (click)="resetPassword()">
					Submit
				</button>
			</div>
		</div>
	</div>
</div>
