import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-info',
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.scss'],
})
export class InfoComponent implements OnInit {
    public title: string;
    public htmlContent: string;

    // constructor() {}

    ngOnInit(): void {}
}
