<div class="uex-dialog-container" tabindex="-1" role="dialog">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title" mat-dialog-title>
					<!-- <i class="fa fa-key"></i> -->
					{{ config.title }}
				</h4>
				<button
					type="button"
					class="close text-white"
					aria-label="Close"
					mat-button
					mat-dialog-close
					id="close-btn"
				>
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<br />
				<div [formGroup]="forgotPasswordForm">
					<div class="input-group mb-3 col-lg-8 col-md-12 offset-lg-2">
						<div class="input-group-prepend">
							<div class="input-group-text bg-white border-right-0"
							[ngClass]="{ 'border-danger': errors.email }"
							>
								<i class="fa fa-at"></i>
							</div>
						</div>
						<input
							type="email"
							class="form-control pl-0 border-left-0"
							[ngClass]="{ 'is-invalid': errors.email }"
							formControlName="email"
							[placeholder]="config.email_placeholder"
							id="forgotPasswordEmail"
						/>
						<div
							class="invalid-feedback"
							*ngIf="errors.email && errors.email?.required"
						>
							{{ config.email_err_message }}
						</div>
						<div
							class="invalid-feedback"
							*ngIf="errors.email && (errors.email?.email || errors.email?.pattern)"
						>
							{{ config.email_err_pattern_message }}
						</div>
					</div>
				</div>
				<br />
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-outline-info text-hover-white" (click)="resetPassword()">
					{{ config.submit_btn_label }}
				</button>
			</div>
		</div>
	</div>
</div>
