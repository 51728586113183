<div class="uex-dialog-container" tabindex="-1" role="dialog">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header bg-info">
				<h1 class="modal-title" mat-dialog-title>
					<i class="fa fa-check-circle"></i> Confirm
				</h1>
				<button
					type="button"
					class="close text-white"
					aria-label="Close"
					mat-button
					mat-dialog-close
				>
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<br />
				<div [innerHTML]="htmlContent"></div>
				<br />
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-outline-secondary" mat-dialog-close>
					Cancel
				</button>
				<button type="button" class="btn btn-outline-info" (click)="confirm(true)">
					Confirm
				</button>
			</div>
		</div>
	</div>
</div>
