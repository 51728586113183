export const PAYMENT_FREQUENCY = 'payment_frequency';
export const BANK_IBAN = 'bank_iban';
export const SENDER_ADDRESS_COUNTRY = 'sender_address_country';
export const BACKEND_DATE_FORMAT = 'YYYY-MM-DD';
export const POLICY_HOLDER = 'POLICY_HOLDER';
export const ADULT_SPOUSE = 'ADULT_SPOUSE';
export const SPOUSE = 'SPOUSE';
export const CHILD = 'CHILD';
export const POLICY_HOLDER_ONLY = 'POLICY_HOLDER_ONLY';
export const COUPLE = 'COUPLE';
export const POLICY_HOLDER_AND_CHILD = 'POLICY_HOLDER_AND_CHILD';
export const FAMILY = 'FAMILY';
export const FAMILY_COMPOSITION = 'FAMILY_COMPOSITION';
export const DANGER_ZONE = 'DANGER_ZONE';
export const ADDRESS_POSTCODE = 'address_postcode';
export const HEALTH_BENEFIT = 'health_benefit';
export const TNS = 'TNS';
export const TNS_LIQUIDATION = 'TNS_LIQUIDATION';
export const EMPLOYEE = 'EMPLOYEE';

export const TEMPORARY_CONTRACT = 'TEMPORARY';
export const ANNUAL_CONTRACT = 'ANNUAL';
export const CONTRACT_DURATION_TYPE = 'CONTRACT_DURATION_TYPE';
export const ENTITY_TYPE = 'ENTITY_TYPE';
export const POLICY_START_DATE = 'policy_start_date';
export const POLICY_END_DATE = 'policy_end_date';
export const BANK_TRANSFER = 'BANK_TRANSFER';
export const BANK_WITHDRAWAL = 'BANK_WITHDRAWAL';
export const CREDIT_CARD = 'CREDIT_CARD';
export const PAYPAL = 'PAYPAL';
export const QUATERLY = 'QUATERLY';
export const SEMI_ANNUAL = 'SEMI_ANNUAL';
export const ANNUAL = 'ANNUAL';
export const PAYMENT_METHOD = 'payment_method';
export const COMPANY = 'COMPANY';
